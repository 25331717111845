<template>
  <div id="app">
<!--    <top-header></top-header>-->
    <div class="content">
      <side-bar></side-bar>
      <router-view />
    </div>
  </div>
</template>

<script>
    // import TopHeader from "../components/TopHeader";
    import SideBar from "../components/SideBar";
    export default {
        components: {
          // TopHeader,
          SideBar
        }
    }
</script>

<style scoped>
  #app {
    background: #f5f8ff;
  }
  .content {
    flex-grow: 1;
    display: flex;
  }
</style>