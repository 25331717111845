<template>
    <div class="navbar-search">
        <img class="navbar-search-icon" src="../assets/images/search.svg" width="22" />
        <input class="navbar-search-input"
               @focus="handleFocus"
               @blur="handleBlur"
               :placeholder="placeholderText"/>
    </div>
</template>

<script>

    export default {
        name: 'SearchBar',
        data() {
            return {
                isFocused: false,
            };
        },
        methods: {
            handleFocus() {
                this.isFocused = true;
            },
            handleBlur() {
                this.isFocused = false;
            },
        },
        computed: {
            placeholderText() {
                return this.isFocused ? 'Type to search...' : 'Search';
            },
        },
    };
</script>

<style scoped lang="scss">
    .navbar-search {
        display: flex;
        align-items: center;
        color: #7d7d7d;
    }

    .navbar-search-icon {
        margin-right: 12px;
        width: 22px;
    }

    .navbar-search-input {
        border: none;
        outline: none;
        font-size: 16px;
        line-height: 24px;
        color: #383838;
    }

    .navbar-search-input:focus::placeholder {
        // color: transparent;
        content: "Type to search..."
    }
</style>
