<template>
  <aside
    :class="{ collapsed }"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
  >
    <TopHeader class="mb-16" />

    <div style="flex-grow: 1">
      <ul class="menu">
        <template v-for="item in items" :key="item.id">
          <li
            v-if="item.access"
            :class="{ active: item.path === currentPath }"
            @click="() => handleClick(item)"
          >
            <div class="icon" :class="makeCssClass(item.id)"></div>
            <div class="label">{{ item.name }}</div>
            <div v-if="item.showSubItems && item?.subItems?.length" class="">
              <router-link
                v-for="sub in item.subItems"
                :key="sub"
                class="label sub-item"
                :to="sub.path"
                >{{ sub.name }}
              </router-link>
            </div>
          </li>
        </template>
      </ul>
    </div>

    <UserBar />

    <div class="aside-arrow" @click="arrowClick" v-html="arrow"></div>
  </aside>
</template>

<script>
// import SlideUpDown from 'vue-slide-up-down'
import TopHeader from '@/components/TopHeader'
import UserBar from '@/components/UserBar'
export default {
  name: 'SideBar',
  components: { UserBar, TopHeader },
  // components: {SlideUpDown},
  data() {
    return {
      items: [
        // {
        //     id: 'dashboard',
        //     name: 'Dashboard',
        // },
        // {
        //     id: 'groups',
        //     name: 'Groups',
        // },
        // {
        //     id: 'timetable',
        //     name: 'Timetable',
        // },
        // {
        //     id: 'course_plan',
        //     name: 'Course plan',
        // },
        {
          id: 'applicants',
          name: 'Applicants',
          path: '/applicants',
          access: this.$can('read', 'students'),
        },
        {
          id: 'users',
          name: 'Users',
          path: '/users',
          access: this.$can('read', 'users'),
        },
        // {
        //   id: 'roles',
        //   name: 'Roles',
        //   path: '/roles',
        // },
        {
          id: 'finance',
          name: 'Finance',
          path: '/payments',
          access: this.$can('read', 'payments'),
        },
        {
          id: 'certificates',
          name: 'Certificates',
          path: '/send-certificates',
          access: this.$can('read', 'certificates'),
        },
        {
          id: 'time-tracker',
          name: 'Time tracker',
          path: '/time-tracker',
          access: this.$can('read', 'time-tracker'),
        },
        {
          id: 'timeslots',
          name: 'Time slots',
          path: '/timeslots',
          access: this.$can('read', 'timeslots'),
        },
      ],
      collapsed: localStorage.getItem('sidebar-collapsed'),
      collapsedEnd: false,
      collapsedTimer: 0,
    }
  },
  mounted() {
    if (this.$route.path === '/') {
      this.$router.push(this.items[0].path)
    }
  },
  computed: {
    arrow() {
      return this.collapsed ? '&#8594;' : '&#8592;'
    },
    currentPath() {
      return this.$route.path
    },
  },
  methods: {
    makeCssClass(id) {
      let obj = {}
      obj[id] = true
      return obj
    },
    handleClick(item) {
      if (item?.subItems?.length) {
        item.showSubItems = !item.showSubItems
      } else {
        this.$router.push(item.path)
      }
    },
    arrowClick() {
      this.collapsed = !this.collapsed
      localStorage.setItem('sidebar-collapsed', this.collapsed)
    },
  },
}
</script>

<style scoped lang="scss">
aside {
  display: flex;
  flex-direction: column;
  flex-basis: 248px;
  flex-shrink: 0px;

  background: #fafafa;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
  padding: 16px 0;

  // transition: all 0.5s ease-in-out;
  overflow-x: hidden;

  position: relative;
}

.label {
  white-space: nowrap;
}

.collapsed {
  flex-basis: 72px;

  .label {
    display: none;
  }
}

.aside-arrow {
  position: absolute;
  right: 0px;
  bottom: 127px;
  width: 36px;
  height: 36px;
  background: #f5f8ff;
  border-radius: 4px 0px 0px 4px;
  font-size: 20px;
  padding-left: 6px;
  cursor: pointer;
  // transition: background .2s ease;
}

.menu {
  list-style: none;
}

.menu li {
  margin: 3px 12px;
  padding: 8px 12px;
  cursor: pointer;

  display: flex;
  align-items: center;
  overflow: hidden;

  font-weight: normal;
  font-size: 16px;
  color: #72849e;
}

.sub-item {
  display: block;
  padding-left: 24px;
  padding-top: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background-color: #72849e;
  mask-repeat: no-repeat;
  mask-position: center;
  margin-top: 1px;
  margin-bottom: 1px;
  flex-shrink: 0;
}

.label {
  margin-left: 12px;
  line-height: 24px;
}

.menu li.active {
  background: #e1e5f9;
  border-radius: 8px;

  .icon {
    background-color: #258ffb;
  }
  .label {
    color: #258ffb;
  }
}

.dashboard {
  mask-image: url('~@/assets/images/menu/dashboard.svg');
}

.groups {
  mask-image: url('~@/assets/images/menu/groups.svg');
}

.timetable {
  mask-image: url('~@/assets/images/menu/timetable.svg');
}

.course_plan {
  mask-image: url('~@/assets/images/menu/course_plan.svg');
}

.applicants {
  mask-image: url('~@/assets/images/menu/application.svg');
}

.users {
  mask-image: url('~@/assets/images/menu/user.svg');
}

.roles {
  mask-image: url('~@/assets/images/menu/roles.svg');
}

.finance {
  mask-image: url('~@/assets/images/menu/wallet.svg');
}

.certificates {
  mask-image: url('~@/assets/images/menu/certificates.svg');
}

.time-tracker {
  mask-image: url('~@/assets/images/menu/time-tracker.svg');
}

.timeslots {
  mask-image: url('~@/assets/images/menu/timeslots.svg');
}
</style>
