<template>
  <div>
    <!--        <img class="navbar-search-icon" src="../assets/images/notification.svg" />-->
    <div class="user">
      <a-popover v-model="userPopoverVisible" trigger="click">
        <template v-slot:content>
          <div style="display: flex;">
            <img class="user-avatar-menu noselect" :src="avatarUrl" />
            <div>
              <span>{{ username }}</span
              ><br />
              <span>{{ email }}</span>
            </div>
          </div>
          <!--<span>{{$t('UserBar.view_profile')}}</span><br>-->
          <!--<span>{{$t('UserBar.settings')}}</span>-->
          <!--<a-divider type="horizontal" />-->
          <!--<span>{{$t('UserBar.teacher_view')}}</span><br>-->
          <!--<span>{{$t('UserBar.manager_view')}}</span>-->
          <a-divider type="horizontal" />
          <span class="logout" @click="logout">{{ $t('UserBar.log_out') }}</span
          ><br />
        </template>
        <img class="user-avatar noselect" :src="avatarUrl" />
        <span class="user-name title-main-text noselect">{{ username }}</span>
      </a-popover>
    </div>
  </div>
</template>

<script>
import config from '../config'
import defaultAvatar from '@/assets/images/empty-user-avatar.svg'
import mapFields from '../utils/mapFields'

export default {
  name: 'UserBar',
  data() {
    return {
      userPopoverVisible: undefined
    }
  },
  computed: {
    ...mapFields({
      email: 'auth.payload.user.email',
      photoPath: 'auth.user.photoSmall.key',
    }),
    username() {
      if (this.$store.state.auth.payload) {
        const { user } = this.$store.state.auth.payload
        if (user.student?.firstName && user.student?.lastName) {
          return `${user.student.firstName} ${user.student.lastName}`
        }
      }
      return this.email
    },
    avatarUrl() {
      if (this.$store.state.auth.payload) {
        const { user } = this.$store.state.auth.payload
        if (user.photoSmall) {
          return `${config.backendUrl}/${user.photoSmall}?token=${localStorage['feathers-jwt']}`
        }
      }
      return defaultAvatar
    },
  },
  methods: {
    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'login' })
    },
  },
}
</script>

<style scoped>
.user {
  height: 100%;
  display: block;
  line-height: normal;
  display: inline-flex;
  justify-content: center !important;
  align-items: center !important;
}

.user-avatar {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-left: 28px;
  border-radius: 100px;
  cursor: pointer;
}

.user-avatar-menu {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 100px;
}

.user-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 28.8px;
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
}

.logout {
  cursor: pointer;
}
</style>

<style scoped lang="scss">
.collapsed {
  .user-avatar {
    margin-left: 22px;
  }
  .user-name {
    display: none;
  }
}
.collapsedEnd {
  .user-name {
    display: none;
  }
}
</style>
