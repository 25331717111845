<template>
  <header>
    <div>
      <img class="logo" src="../assets/images/logo.png"/>
      <img class="logo_mini" src="../assets/images/logo_mini.png"/>
    </div>
  </header>
</template>

<script>
import SearchBar from "./SearchBar";
import UserBar from "./UserBar";

export default {
  name: "TopHeader",
  components: {UserBar, SearchBar}
}
</script>

<style scoped lang="scss">
header {
  height: 70px;
  text-align: center;
  padding: 16px;
}

.logo {
  height: 49px;
  margin-left: -62px;
}

.expand {
  flex-grow: 1;
}

.logo_mini {
  display: none;
  width: 40px;
}

.collapsed {
  .logo {
    display: none;
  }

  .logo_mini {
    display: block;
  }
}
</style>